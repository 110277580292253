/* eslint-disable no-useless-escape */
import { EShopSettings } from '@/enums/shop.settings.enum'
const state = () => ({
  settings: null,
  info: null,
  pages: null,
  fcmToken: null,
  playerId: null,
  errors: null,
  qrMenu: window.QR_MENU,
})

const getters = {
  recaptchaEnabled: (s) => s.settings?.reCAPTCHA_v3 === '1',

  isGift: (s) => s.settings?.present_position === '',

  errors: (s) => s.errors,

  social: (s) => s.settings.social,

  isBottomPresentPosition: (s) => s.settings?.present_position === 'bottom',

  shopSettings: (s) => {
    const shopSettings = s.settings?.shop_settings
    return ({ strategy }) => {
      const coercedShopSettings = ({ strategy = EShopSettings.DEFAULT }) => {
        switch (strategy) {
          case EShopSettings.SERVED:
            return /^(served!?[\_\-]!?shop!?[\_\-])/gim
          case EShopSettings.CLOSED:
            return /^(closed!?[\_\-]!?shop!?[\_\-])/gim
          case EShopSettings.FEASTED:
            return /^(closed!?[\_\-]!?holiday!?[\_\-])/gim
          case EShopSettings.ORDERED:
            return /^(disabled!?[\_\-]!?order!?[\_\-])/gim
          default:
            return null
        }
      }
      try {
        if (!shopSettings) {
          throw new TypeError(shopSettings)
        }
        const currentShopReg = coercedShopSettings({ strategy })
        if (!(currentShopReg instanceof RegExp)) {
          throw new TypeError(currentShopReg)
        }
        const shopSettingsKeys = Reflect.ownKeys(shopSettings)
        const shopCurrentSettings = {}
        shopSettingsKeys
          .filter((setting) => setting.match(currentShopReg))
          .forEach((setting) => {
            shopCurrentSettings[
              setting?.replace(currentShopReg, '') || setting
            ] = shopSettings && shopSettings[setting]
          })
        return shopCurrentSettings
      } catch (e) {
        if (e instanceof TypeError) return null
      }
    }
  },
  phone: (s) => s.settings?.phone,
  phone2: (s) => s.settings?.phone2,
  logo: (s) => s.settings?.logo,
  name: (s) => s.info?.data?.merchant_name,
  geoMap: (s) => s.settings?.merchant_maps_main || false,
  geoMapApiKey: (s) => s.settings?.google_geo_api_key || false,
  isIikoEnabled: (s) => Boolean(s.settings?.iiko_cart_calc === 'Y'),
  phonePrefix: (s) => s.settings?.mobile_prefix,
  successOrderMsg: (s) => s.settings?.success_order_msg,
  phoneMask: (s, { phonePrefix }) => {
    return s.settings?.mobile_mask || `${phonePrefix} (###) ###-##-##`
  },
  appShop: ({ settings: { app_shop: appShop } }) => appShop?.speen2win || {},
  city: (s) => s.settings.city,
  cities: (s) => s.settings?.branches,
  useStreetAutocomplete: (s) => s.settings.autocomplete_street == 2,
  useGeoAddressDelivery: (s) => s.settings.delivery_zones == 2,
  fieldsAddonsSettings: (s) => s.settings?.fields_addons_settings,
  canUseBonuses: (s) => !!s.settings.has_pts,
  canUsePromo: (s) => s.settings.merchant_enabled_voucher !== 'yes',
  additionalFields: (s) => s.settings.fields_addons,
  orderEnsureLabel: (s) => s.settings.fields_addons?.call_me,
  useReactiveSmsGateway: (s) => Boolean(+s.settings?.is_flashcall === 1),

  alternativeAuth: (s) => s.settings.sms_client_auth === '1',
  currency: (s) => {
    let currency = s.settings.currency_symbol
    if (currency === ' Р' || !currency) currency = ' ₽'
    return currency
  },
  isWhiteLabel: (s) => Boolean(s.settings?.disabled_whitelabel),
  isQrMenu: (s) => s.qrMenu,
  useDeliveryZones: (s) => Boolean(s.settings.delivery_zones),
  addressModalAutoOpen: (s) =>
    Boolean(!s.settings.theme_option.config?.express_delivery_type),
  orderTypeButtonsEnabled: (s) =>
    Boolean(!s.settings.theme_option.config?.disabled_order_type_modal),
}

const mutations = {
  setSettings(state, value) {
    state.settings = value
  },

  setError(state, value) {
    state.errors = value
  },

  setMerchantInfo(state, value) {
    state.info = value
  },

  setPages(state, value) {
    state.pages = value
  },

  setFcmToken(state, value) {
    state.fcmToken = value
  },

  setPlayerId(state, value) {
    state.playerId = value
  },
}

const actions = {
  async loadSettings({ commit }) {
    const response = await this.$api.get('getAppSettings')
    if (response.code == 1) {
      commit('setSettings', response.details)

      // localStorage.setItem("merchantKey", response?.get?.merchant_keys);
      if (response.details.metrica_head) {
        const script = document.createElement('script')
        script.innerHTML = response.details.metrica_head
        document.head.appendChild(script)
      }
    } else if (response.code == 11) {
      commit('setError', response)
    } else {
      localStorage.removeItem('merchantKey')
    }
  },

  async getMerchantInfo({ commit }) {
    const response = await this.$api.get('getMerchantInfo')
    if (response.code == 1) {
      commit('setMerchantInfo', response.details)
    }
  },

  async loadPages({ commit }) {
    const response = await this.$api.get('loadPages')
    if (response.code == 1) {
      commit('setPages', response?.details?.data)
    }
  },

  async loadPage(store, id) {
    const response = await this.$api.get('getDetailPage', { params: { id } })
    return response?.details?.data
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
